.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding: 13px 44px;
  color: var(--black);
  border-bottom: 1px solid var(--dark-gray);
}

.hideHeader {
  display: none;
}

.navBar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;
}

.navLinks {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10px 4px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.navLinks li {
  display: inline;
}
