.Button {
  border: none;
  display: block;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: 0.1s;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.fill {
  width: 100%;
}

.medium {
  padding: 8px 16px;
}

.large {
  padding: 12px 24px;
}

.primary {
  color: white;
  background-color: var(--black);
}

.primary:hover,
.primary:focus {
  background-color: #232b34;
}

.secondary {
  color: #232b34;
  background-color: white;
  border: 1px solid #232b34;
}

.secondary:hover,
.secondary:focus {
  border: 1px solid #37586c;
  color: #37586c;
}

.auth {
  background-color: var(--green);
  color: var(--white);
}

.start {
  background-color: var(--green);
  color: var(--white);
}

.stop {
  background-color: red;
  color: var(--white);
}
