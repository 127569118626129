.map_controls {
    justify-content: flex-start;
}

.map_container {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
