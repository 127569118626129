.map_controls {
    display: flex;
    justify-content: flex-start;
}

.map_container {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ui-button {
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    outline: none;
  }
