.PlayerMapper_Container {
  padding: 10px;
}

.Segment_Title {
  display: block;
  float: right;
}

.Buttons_Container {
  display: flex;
  justify-content: flex-start;
}

video {
  width: 98%;
  height: 100%;
}
