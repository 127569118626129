.Player_Container {
  width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Buttons_Container {
  display: flex;
  justify-content: flex-start;
}

/* Style for the video */
video {
  width: 98%;
  height: 100%;
}
