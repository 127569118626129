.App {
  text-align: center;
  background-color: grey;
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.active-row {
  background-color: #1890ff !important;
  color: white;
}

.active-row:hover td {
  background-color: #1890ff !important;
  color: white;
}
