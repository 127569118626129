:root {
  --black: #111827;
  --white: #ffffff;
  --dark-gray: #d1d5db;
  --light-gray: #f3f4f6;
  --green: #064e3b;
  --font-family: 'Noto Sans', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
