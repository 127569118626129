/* src/pages/LoginPage.css */
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: var(--white);
  padding: 40px 40px 20px 40px;
  border-radius: 24px;
  border: 1px solid var(--dark-gray);
  text-align: center;
  width: 663px;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-input,
.ant-input-password {
  border-radius: 2px;
}
