.Table_Container {
  width: 100%;
}

.container-button {
  display: flex;
  flex-direction: column;
}

.container-button-editable {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.active-row {
  background-color: #1890ff !important;
  color: white;
}

.name-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.ant-table-placeholder {
  /*  TODO as `useSortable` works incorrectly "No Data" placeholder is overlapping filter's dropdowns   */
  z-index: 1 !important;
}
