.containerLogo {
  display: flex;
  align-items: center;
}

.loginContainer {
  justify-content: center;
  padding-bottom: 20px;
}

.logo {
  width: 31px;
  height: 34px;
}

.loginLogo {
  width: 47px;
  height: 51px;
}

.logoText {
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  margin-left: 6px;
}

.loginLogoText {
  font-size: 27px;
  margin-left: 12px;
  line-height: 1.1;
}
