.nav-link {
  color: var(--black);
  background-color: var(--white);
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 8px;
}

.active,
.active:focus {
  color: var(--white);
  background-color: var(--black);
}

.active:hover {
  background-color: #232b34;
}
